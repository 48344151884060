/**
 * Checks if all required payout requisites are filled
 * @param {Object} requisites - `user.requisites` object from server
 * @returns {Boolean}
 */
export default function checkRequisites(requisites) {
  const requiredFields = [
    'accountNumber',
    'address',
    'bankName',
    'city',
    'countryResidence',
    'country',
    'legalStatus',
    'name',
  ];

  const areRequiredFieldsFilled = requiredFields.every(
    (field) => !!requisites[field]
  );
  // const isFormFileValid =
  //   (requisites.countryResidence?.name === 'Russia' && !requisites.formFile) ||
  //   (requisites.countryResidence?.name !== 'Russia' && !!requisites.formFile);

  const isBankCodeValid =
    (requisites.countryResidence?.name === 'Russia' && !!requisites.bankBic) ||
    (requisites.countryResidence?.name !== 'Russia' && !!requisites.bankSwift);

  return areRequiredFieldsFilled && isBankCodeValid;
}
